
html, body, #root {
  height: 100%;
  width: 100%;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  } to {
    transform: rotate(-360deg);
  }
}
